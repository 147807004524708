import React from "react"
import './NoAccess.scss';
import {Button} from "antd";
import * as firebase from "firebase";

const NoAccess: React.FC = () => {

  const logout = () => {
    console.log("Attempting to sign out from firebase");
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.error("Error when signing out: ", error)
    });
  }

  return (
    <div className="login-container">
      <h2 style={{ marginBottom: 8 }}>Please get RAS to give you permission to view this demo.</h2>
      <span>(If you are not sure who to contact, look for Ryan)</span>
      <h2 style={{ marginTop: 32, marginBottom: 32 }}>Once done try to access the link again, or refresh this page.</h2>
      <Button onClick={() => logout()}>Logout</Button>
    </div>
  );
}

export default NoAccess
