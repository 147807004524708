import React, {FC} from 'react';
import ReactDOM from "react-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import {User} from "../types/user";

declare global {
    interface Window {
        requestMessageNotification: Function;
        onTranChangeLanguage: Function;
        OpenFAQ: Function;
    }
}

interface HeaderProps {
    user: User;
}

const Header: FC<HeaderProps> = ({ user }) => {

    const requestMessageNotification = () => {
        if (window.requestMessageNotification) {
            window.requestMessageNotification();
        }
    }

    const onTranChangeLanguage = (languageKey: string) => {
        if (window.onTranChangeLanguage) {
            window.onTranChangeLanguage(languageKey);
        }
    }

    const openFAQ = (path: string) => {
        if (window.OpenFAQ) {
            window.OpenFAQ(path);
        }
    }

    const logout = () => {
        console.log("Attempting to sign out from firebase");
        firebase.auth().signOut().then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
            console.error("Error when signing out: ", error)
        });
    }

    return ReactDOM.createPortal(
        <div className="l_head_content">
            <h1 className="site_title">
                <a href="/" title="OCBC Bank"> OCBC Bank </a>
            </h1>

            <div className="user_nav ">
                <div className="user_nav_cont">
                    <div className="user_greet" style={{ width: 490 }}>
                        <p>
                            <span style={{ float: 'left', clear: 'both' }}>Good afternoon,</span>
                            <span className="user_name" style={{ color: '#1480B7', fontStyle: 'normal', float: 'left' }}>
                                {user?.displayName || "stranger"}
                            </span>
                        </p>
                        <p className="user_company wrap_and_break"
                            style={{ float: 'left', clear: 'both', fontStyle: 'normal' }}>
                            RED AIRSHIP HOLDINGS PTE. LTD.
                        </p>
                        <p className="last_login"
                            style={{ float: 'left', clear: 'both', fontStyle: 'normal', marginTop: 'unset' }}>
                            Last Login :
                            19 Jul 2020 12:14 PM
                        </p>
                    </div>
                </div>
                {/*end: user navigation content*/}

                <div className="user_nav_cont">
                    <div className="user_greet">
                        English | <a href="/#" onClick={() => onTranChangeLanguage('zh_CN')}>中文</a>
                        <p className="user_datetime">Language</p>
                    </div>
                </div>

                <div className="user_nav_cont last">
                    <ul className="user_panel clearfix">
                        <li id="msgList">
                            <a className="user_panel_lnk" href="/#" title="Mailbox"
                                onMouseOver={() => requestMessageNotification()}>
                                <div className="decor_l">
                                    <div className="decor_r">
                                        <span className="icn lnk_msg"></span> <span id="msgNotification"></span>Mailbox
                                    </div>
                                </div>
                            </a>
                            <div id="msgContainer">

                            </div>
                        </li>
                        {/*end: show dropdown*/}
                        <li>
                            <a href="/#" onClick={() => openFAQ('/doc/user_guide/en_GB/SG/velocity2.0_userguide_SG_EN.pdf')} className="user_panel_lnk" title="Help">
                                <div className="decor_l">
                                    <div className="decor_r">
                                        <span className="icn lnk_help"></span> Help
                                    </div>
                                </div>
                            </a>
                        </li>
                        {/*end: user panel help*/}
                        <li>
                            <a href="/#" className="user_panel_lnk" onClick={() => logout()} title="Logout">
                                <div className="decor_l">
                                    <div className="decor_r">
                                        <span className="icn lnk_logout"></span> Logout
                                    </div>
                                </div>
                            </a>
                        </li>
                        {/*end: user panel logout*/}
                    </ul>
                    {/*end: user panel*/}
                </div>
            </div>
            {/*end: user navigation content*/}
            {/*end: user nav*/}
        </div>,
        document.getElementById('l_head')!
    );
}

export default Header;
