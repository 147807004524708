import React, {FC, useContext, useEffect} from 'react';
import Header from "../../components/Header";
import MainNavigation from "../../components/main-navigation/MainNavigation";
import {User} from "../../types/user";
import MenuConfiguration from "../../../features/menu-configuration/MenuConfiguration";
import {AppContext} from "../../../App";
import UserManagement from "../../../features/user-management/UserManagement";

interface VelocityContainerProps {
  user: User;
}

const VelocityContainer: FC<VelocityContainerProps> = ({ user }) => {

  const {
    showMenuConfiguration,
    showUserManagement,
  } = useContext(AppContext);

  const isViewer = user ? user.isViewer : false;

  // Show main frameset if user is viewer.
  useEffect(() => {
    const mainFrameSet = document.getElementById("mainFrameSet");
    if (isViewer) {
      if (mainFrameSet) {
        mainFrameSet.style.visibility = 'visible';
      }
    } else {
      if (mainFrameSet) {
        mainFrameSet.style.visibility = 'hidden';
      }
    }
  }, [isViewer]);

  return (
    <>
      <Header user={user} />
      <MainNavigation />
      {showMenuConfiguration && (
        <MenuConfiguration />
      )}
      {showUserManagement && (
        <UserManagement />
      )}
    </>
  )
}

const VelocityContainerWrapper: FC = () => {

  const {
    user,
  } = useContext(AppContext);

  if (!user) {
    return null;
  }

  return <VelocityContainer user={user} />;
}

export default VelocityContainerWrapper;
