import React, {useEffect, useState} from 'react';

const Welcome = () => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    // console.log('Demo page loaded');

    if (window === window.top) {
        // Not in an iframe? Redirect.
        window.location.href = '/container.html';
        return;
    }

    if (!ready) {
        setReady(true);
    }

    window.parent.postMessage({actionType:'SET_HEIGHT', payload: 1000}, '*');
  }, [ready]);

  if (!ready) {
      return (<div></div>);
  }

  // Note: velocity uses .l_page to get page content height if existing.
  return (
      <div style={{ padding: '64px 0 64px', margin: '0 auto', width: 946 }}>
        <h1>Welcome to RAS Velocity Demo</h1>
        <p>Please use the "RAS" menu to access demo pages.</p>
        <p>Nothing else other than the demo pages works as this is a demo, not the real BV!</p>
      </div>
  );
}

export default Welcome;
