import {User} from "../../common/types/user";
import React, {FC, useEffect, useState} from "react";
import {Checkbox, Form, Input, Modal, notification, Select, Spin} from "antd";
import * as firebase from "firebase";

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const formTailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

interface UserFormModalProps {
  user: User;
  existingRoles: string[];
  visible: boolean;
  onCancel: () => void;
  onClose: () => void;
}

const UserFormModal: FC<UserFormModalProps> = ({
  user,
  existingRoles,
  visible,
  onCancel,
  onClose,
}) => {

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [user]);

  const handleUpdate = async (values: any) => {
    console.log('[handleUpdate] values:', values);
    if (!user) {
      console.error('[handleUpdate] user is empty.');
      return;
    }

    setIsSubmitting(true);

    // Clean up values.
    const {
      displayName,
      isAdmin,
      isEditor,
      roles,
    } = values as User;

    const isViewer = roles.some(role => role.toLowerCase() === "viewer");

    const updates = {
      displayName,
      isAdmin,
      isEditor,
      isViewer,
      roles,
    };

    // Updating user.
    try {
      await firebase.firestore()
        .collection("users")
        .doc(user.key)
        .update(updates);
    } catch (error) {
      console.error('Error updating user:', error);
      setIsSubmitting(false);
      return;
    }

    notification.success({
      message: 'Updated',
      description: `User ${user.displayName} is updated.`,
    });

    onClose();
  };

  const title = "Update user";
  const okText = isSubmitting ? "Submitting..." : "Update";

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values: any) => {
            await handleUpdate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      maskClosable={false}
    >
      {isSubmitting && <div style={{ textAlign: 'center', padding: 32 }}><Spin size="large" /></div>}
      <Form
        {...formLayout}
        form={form}
        name="user"
        initialValues={user}
        hidden={isSubmitting}
      >
        <Form.Item name="displayName" label="Display name" rules={[{ required: true, message: 'Required' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="key" label="Key">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item {...formTailLayout} name="isAdmin" valuePropName="checked">
          <Checkbox disabled={true}>User is admin (can manage editors)</Checkbox>
        </Form.Item>
        <Form.Item {...formTailLayout} name="isEditor" valuePropName="checked">
          <Checkbox disabled={user.isAdmin} checked={user.isAdmin}>User is editor (can manage viewers)</Checkbox>
        </Form.Item>
        <Form.Item name="roles" label="Roles" rules={[{ required: true, message: 'Required' }]}>
          <Select mode="tags" style={{ width: '100%' }} tokenSeparators={[',']}>
            {existingRoles.map(role => (
              <Select.Option key={role} value={role}>{role}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UserFormModal;
