import React, {createContext, useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import Welcome from "./features/welcome/Welcome";
import VelocityContainer from "./common/containers/velocity-container/VelocityContainer";
import Login from "./features/login/Login";
import {User, userHasRole} from "./common/types/user";
import NoAccess from "./features/no-access/NoAccess";
import {notification, Spin} from "antd";

const firebaseConfig = {
  apiKey: "AIzaSyDR0Qz1tr_3898JiZK0UIR1VuJluM9ow4c",
  authDomain: "ocbc-velocity.firebaseapp.com",
  databaseURL: "https://ocbc-velocity.firebaseio.com",
  projectId: "ocbc-velocity",
  storageBucket: "ocbc-velocity.appspot.com",
  messagingSenderId: "20365064579",
  appId: "1:20365064579:web:7212be3e073ddc43bfdeb6",
  measurementId: "G-7D315PVCV3"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export interface AppContextProps {
  user?: User;
  showMenuConfiguration: boolean;
  setShowMenuConfiguration: (value: boolean) => void;
  showUserManagement: boolean;
  setShowUserManagement: (value: boolean) => void;
}
export const AppContext = createContext<AppContextProps>({
  showMenuConfiguration: false,
  setShowMenuConfiguration: a => {},
  showUserManagement: false,
  setShowUserManagement: a => {},
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userData, setUserData] = useState<User>();
  const [showMenuConfiguration, setShowMenuConfiguration] = useState<boolean>(false);
  const [showUserManagement, setShowUserManagement] = useState<boolean>(false);

  useEffect(() => {
    // Listen to the Firebase Auth state and set the local state.
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (user) => {
        setUserData(undefined);
        setIsLoggedIn(false);

        if (!user) {
          return;
        }

        setIsLoggedIn(true);

        // Get some dimensions for analytics/
        const windowWidth = window.innerWidth
          || document.documentElement.clientWidth
          || document.body.clientWidth;

        const windowHeight = window.innerHeight
          || document.documentElement.clientHeight
          || document.body.clientHeight;

        const screenWidth = window.screen.width * window.devicePixelRatio;
        const screenHeight = window.screen.height * window.devicePixelRatio;

        const userAgent = navigator ? navigator.userAgent || "" : "";

        let result: any;
        try {
          const initializeUser = firebase.functions().httpsCallable('initializeUser');
          const response = await initializeUser({
            user: user.toJSON(),
            agent: userAgent,
            windowWidth,
            windowHeight,
            screenWidth,
            screenHeight,
          });
          result = response?.data;
          // console.log('result:', result);
        } catch (error) {
          console.error("Error calling initializeUser, error:", error);
          notification.error({
            message: "Error initializing user",
            description: "Check console for more details.",
          });
          return;
        }

        if (!result) {
          notification.error({
            message: "Error initializing user",
            description: "Did not receive a valid response from server!",
          });
          return;
        }

        const { isError, message, userData: userDataFromServer = {} } = result;
        if (isError) {
          notification.error({
            message: "Error initializing user",
            description: message,
          });
          return;
        }

        notification.success({
          message: "Initialized user",
          description: message,
        });
        const userData = userDataFromServer;
        // console.log('userDataFromServer:', userDataFromServer);

        // Abort if no user data.
        if (!userData) {
          console.error("Could not load user data.");
        }

        setUserData(userData);
      }
    );

    return () => {
      // Make sure we un-register Firebase observers when the component unmounts.
      unregisterAuthObserver();
    };
  }, []);


  // Show main frameset if user is viewer.
  useEffect(() => {
    // console.log('[App] userData:', userData);
    const isViewer = userData ? userData.isViewer : false;
    // console.log('[App] isViewer:', isViewer ? 'Y' : 'N');
    const mainFrameSet = document.getElementById("mainFrameSet");
    if (isViewer) {
      if (mainFrameSet) {
        mainFrameSet.style.visibility = 'visible';
      }
    } else {
      if (mainFrameSet) {
        mainFrameSet.style.visibility = 'hidden';
      }
    }
  }, [userData]);

  // Show login UI if not logged in.
  if (!isLoggedIn) {
    return (
      <Login />
    );
  }

  // console.log('userData:', userData);

  // Logged in but initializing and loading user.
  if (!userData) {
    return (<div style={{ padding: '264px 64px 64px', textAlign: 'center' }}><Spin size="large" /></div>)
  }

  if (!userData.isViewer) {
    return (
      <NoAccess />
    );
  }

  return (
      <AppContext.Provider value={{
        user: userData,
        showMenuConfiguration,
        setShowMenuConfiguration,
        showUserManagement,
        setShowUserManagement,
      }}>
        <Router>
          <Switch>
            <Route exact path="/welcome">
              <Welcome />
            </Route>
            <Route exact path="/">
              <VelocityContainer />
            </Route>
          </Switch>
        </Router>
      </AppContext.Provider>
  );
}

export default App;
