import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import ReactDOM from "react-dom";
import * as firebase from "firebase";
import {
  Badge,
  Checkbox,
  Form,
  Input,
  Button,
  List,
  Menu,
  notification,
  Select,
  Table,
  Tag,
  Space,
  Switch,
  Modal,
  Popconfirm, Spin
} from "antd";
import { User } from "../../common/types/user";
import { MenuItem } from "../../common/types/menuItem";
import "./MenuConfiguration.scss";
import {Project} from "../../common/types/project";
import {AppContext} from "../../App";

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const formTailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

// Key property is to identify rows. Without it, every row expands whenever one row is expanded.
// We can't name childrenMenuItems 'children' as this is picked up by antd table to automatically render parent rows.
interface ProjectTableGroup {
  key: string;
  project: string;
  childrenMenuItems: MenuItem[];
}

interface MenuItemConfigurationFormModalProps {
  user: User;
  menuItem: MenuItem;
  projects: Project[];
  visible: boolean;
  onCancel: () => void;
  onClose: () => void;
}

const MenuItemConfigurationFormModal: FC<MenuItemConfigurationFormModalProps> = ({
                                                                                   menuItem,
                                                                                   projects,
                                                                                   visible,
                                                                                   onCancel,
                                                                                   onClose,
}) => {

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<any>({});
  const [isComingSoon, setIsComingSoon] = useState<boolean>(false);
  const [hasConfig, setHasConfig] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const initialValues = {
      project: menuItem.project,
      label: menuItem.label,
      isEnabled: menuItem.isEnabled,
      isComingSoon: menuItem.isComingSoon || false,
      url: menuItem.url,
      hasConfig: menuItem.hasConfig || false,
      configUrl: menuItem.configUrl,
      requiredRole: menuItem.requiredRole,
    }

    setIsComingSoon(initialValues.isComingSoon)
    setHasConfig(initialValues.hasConfig)
    setInitialValues(initialValues);

    // form.resetFields();
  }, [menuItem]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const handleUpdate = async (values: any) => {
    console.log('[handleUpdate] values:', values);
    if (!menuItem) {
      console.error('[handleUpdate] menuItem is empty.');
      return;
    }
    setIsSubmitting(true);

    // Clean up values.
    const {
      project,
      isEnabled = true,
      label,
      isComingSoon = false,
      url = "",
      hasConfig = false,
      configUrl = "",
      requiredRole = "viewer",
    } = values as MenuItem;

    const updates = {
      project,
      isEnabled,
      label,
      isComingSoon,
      url,
      hasConfig,
      configUrl,
      requiredRole,
      isDeleted: false,
    };

    if (!menuItem.key) {
      // Creating new menu item.
      try {
        await firebase.firestore()
          .collection("menuItems")
          .doc()
          .set(updates);
      } catch (error) {
        console.error('Error creating menu item:', error);
        setIsSubmitting(false);
        return;
      }

      notification.success({
        message: 'Created',
        description: `"${updates.label}" created.`,
      });
    } else {
      // Updating menu item.
      try {
        await firebase.firestore()
          .collection("menuItems")
          .doc(menuItem.key)
          .update(updates);
      } catch (error) {
        console.error('Error updating menu item:', error);
        setIsSubmitting(false);
        return;
      }

      notification.success({
        message: 'Updated',
        description: `"${menuItem.label}" is updated.`,
      });
    }
    onClose();
  };

  // console.log('[MenuItemConfigurationFormModal] menuItem:', menuItem);

  const title = menuItem ? "Update menu item" : "Create menu item";
  let okText = menuItem ? "Update" : "Create";

  if (isSubmitting) {
    okText = "Submitting...";
  }

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values: any) => {
            await handleUpdate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      maskClosable={false}
    >
      {isSubmitting && <div style={{ textAlign: 'center', padding: 32 }}><Spin size="large" /></div>}
      <Form
        {...formLayout}
        form={form}
        name="menuItem"
        initialValues={initialValues}
        hidden={isSubmitting}
      >
        <Form.Item
          name="project"
          label="Project"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Select
            placeholder="Select an existing project"
          >
            {projects.map(project => {
              return (<Option key={project.key} value={project.key}>{project.label}</Option>);
            })}
          </Select>
        </Form.Item>
        <Form.Item name="label" label="Label" rules={[{ required: true, message: 'Required' }]}>
          <Input />
        </Form.Item>
        <Form.Item {...formTailLayout} name="isEnabled" valuePropName="checked">
          <Checkbox>Enabled</Checkbox>
        </Form.Item>
        <Form.Item {...formTailLayout} name="isComingSoon" valuePropName="checked">
          <Checkbox checked={isComingSoon} onChange={(e) => setIsComingSoon(e.target.checked)}>Coming soon (no url yet)</Checkbox>
        </Form.Item>
        {!isComingSoon && (
          <Form.Item name="url" label="Url" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>
        )}
        <Form.Item {...formTailLayout} name="hasConfig" valuePropName="checked">
          <Checkbox checked={hasConfig} onChange={(e) => setHasConfig(e.target.checked)}>Has config</Checkbox>
        </Form.Item>
        {hasConfig && (
          <Form.Item name="configUrl" label="Config url" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>
        )}
        <Form.Item label="Required role" required={true}>
          <Form.Item name="requiredRole" noStyle rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>
          <span>Users need this role to view this menu item.</span>
        </Form.Item>
      </Form>
    </Modal>
  );
}


interface ProjectConfigurationFormModalProps {
  user: User;
  project?: Project;
  projects: Project[];
  visible: boolean;
  onCancel: () => void;
  onClose: () => void;
}

const ProjectConfigurationFormModal: FC<ProjectConfigurationFormModalProps> = ({
  project,
  projects,
  visible,
  onCancel,
  onClose,
}) => {

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [project]);

  const handleUpdate = async (values: any) => {
    console.log('[handleUpdate] values:', values);
    if (!project) {
      console.error('[handleUpdate] project is empty.');
      return;
    }

    setIsSubmitting(true);

    // Clean up values.
    const {
      label,
    } = values as Project;

    if (!project?.key) {
      const newObject = {
        label,
        isDeleted: false,
      };

      // Creating new project.
      try {
        await firebase.firestore()
          .collection("projects")
          .doc()
          .set(newObject);
      } catch (error) {
        console.error('Error creating project:', error);
        setIsSubmitting(false);
        return;
      }

      notification.success({
        message: 'Created',
        description: `"${newObject.label}" created.`,
      });
    } else {
      // Updating project.
      const updates = {
        label,
      };

      try {
        await firebase.firestore()
          .collection("projects")
          .doc(project.key)
          .update(updates);
      } catch (error) {
        console.error('Error updating project:', error);
        setIsSubmitting(false);
        return;
      }

      notification.success({
        message: 'Updated',
        description: `"${project.label}" is updated.`,
      });
    }

    onClose();
  };

  const title = project ? "Update project" : "Create project";
  let okText = project ? "Update" : "Create";

  if (isSubmitting) {
    okText = "Submitting...";
  }

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values: any) => {
            await handleUpdate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      maskClosable={false}
    >
      {isSubmitting && <div style={{ textAlign: 'center', padding: 32 }}><Spin size="large" /></div>}
      <Form
        {...formLayout}
        form={form}
        name="project"
        initialValues={project}
        hidden={isSubmitting}
      >
        <Form.Item name="label" label="Label" rules={[{ required: true, message: 'Required' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

interface MenuConfigurationProps {}

const MenuConfiguration: FC<MenuConfigurationProps> = () => {

  const {
    user,
    setShowMenuConfiguration,
  } = useContext(AppContext);

  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>();
  const [showEditMenuItemModal, setShowEditMenuItemModal] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [showEditProjectModal, setShowEditProjectModal] = useState<boolean>(false);

  // Keep menuItems updated by listening to menuItems collection on firestore.
  useEffect(() => {
    let stopListening: Function;
    try {
      stopListening = firebase.firestore()
        .collection("menuItems")
        .where("isDeleted", "==", false)
        .onSnapshot(function(querySnapshot) {
          const menuItems: MenuItem[] = [];
          querySnapshot.forEach(function(doc) {
            const menuItem = doc.data() as MenuItem;
            menuItem.key = doc.id;
            menuItems.push(menuItem);
          });
          setMenuItems(menuItems);
        });
    } catch (error) {
      console.error('Error loading menu items:', error);
      return;
    }

    return () => {
      if (stopListening) {
        stopListening();
      }
    }
  }, []);

  // Keep projects updated by listening to projects collection on firestore.
  useEffect(() => {
    let stopListening: Function;
    try {
      stopListening = firebase.firestore()
        .collection("projects")
        .where("isDeleted", "==", false)
        .onSnapshot(function(querySnapshot) {
          const projects: Project[] = [];
          querySnapshot.forEach(function(doc) {
            const project = doc.data() as Project;
            project.key = doc.id;
            projects.push(project);
          });
          projects.sort((a: Project, b: Project) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA === nameB) {
              return 0;
            }
            return (nameA < nameB) ? -1 : 1;
          });
          setProjects(projects);
        });
    } catch (error) {
      console.error('Error loading projects:', error);
      return;
    }

    return () => {
      if (stopListening) {
        stopListening();
      }
    }
  }, []);

  if (!user) {
    return null;
  }

  const tableDataKeys: string[] = [];
  const tableDataHash: { [key: string]: ProjectTableGroup } = {};
  projects.forEach(project => {
    const projectKey = project.key;
    if (typeof tableDataHash[projectKey] === "undefined") {
      tableDataHash[projectKey] = {
        key: projectKey,
        project: project.label,
        childrenMenuItems: [],
      };
      tableDataKeys.push(projectKey);
    }
  });
  console.log('menuItems:', menuItems);
  menuItems.forEach(menuItem => {
    const projectKey = menuItem.project;
    if (typeof tableDataHash[projectKey] === "undefined") {
      tableDataHash[projectKey] = {
        key: projectKey,
        project: 'Unknown',
        childrenMenuItems: [],
      };
      tableDataKeys.push(projectKey);
    }
    tableDataHash[projectKey].childrenMenuItems.push(menuItem);
  });

  const tableData: ProjectTableGroup[] = [];
  tableDataKeys.forEach(key => {
    tableData.push(tableDataHash[key]);
  });

  const handleEditProject = (projectItem: ProjectTableGroup) => {
    console.log('[handleEditProject] projectItem:', projectItem);
    const project = {
      key: projectItem.key,
      label: projectItem.project,
    } as Project;
    setSelectedProject(project);
    setShowEditProjectModal(true);
  };

  const closeUpdateProjectModal = () => {
    setShowEditProjectModal(false);
    setSelectedProject(undefined);
  }

  const handleCancelUpdateProject = () => {
    setShowEditProjectModal(false);
  };

  const queryNumberOfMenuItemsByProjectKey = async (projectKey: string) => {
    const menuItemsQuery = firebase.firestore()
      .collection("menuItems")
      .where("project", "==", projectKey);

    let numberOfMenuItems = 0;
    try {
      const menuItems = await menuItemsQuery.get();

      menuItems.forEach((document) => {
        const menuItemInfo = document.data() as MenuItem;
        if (!menuItemInfo.isDeleted) {
          numberOfMenuItems++;
        }
      });

    } catch (error) {
      console.error('Error checking if project contains menu items:', error);
      return -1;
    }

    return numberOfMenuItems;
  }

  const handleDeleteProject = async (project: Project) => {
    if (!project.key) {
      console.error("Cannot delete a project that is not created yet.");
      return;
    }

    // Check if there are any menu items associated.
    const numberOfMenuItems = await queryNumberOfMenuItemsByProjectKey(project.key) || 0;
    if (numberOfMenuItems === -1) {
      notification.error({
        message: 'Could not delete project',
        description: `Could not determine if project "${project.label}" has any menu items.`,
      });
      return;
    }
    if (numberOfMenuItems > 0) {
      notification.error({
        message: 'Could not delete project',
        description: `Project "${project.label}" has ${numberOfMenuItems} menu items. Only non-empty projects can be deleted.`,
      });
      return;
    }

    try {
      await firebase.firestore()
        .collection("projects")
        .doc(project.key)
        .update({
          isDeleted: true,
        });
    } catch (error) {
      console.error('Error deleting project:', error);
      return;
    }

    notification.warning({
      message: 'Deleted',
      description: `Project "${project.label}" is deleted. Click here to undo in the next 4 seconds.`,
      onClick: () => {
        handleUndoDeleteProject(project);
      },
    });
  }

  const handleUndoDeleteProject = async (project: Project) => {
    if (!project.key) {
      console.error("Cannot delete a project that is not created yet.");
      return;
    }

    try {
      await firebase.firestore()
        .collection("projects")
        .doc(project.key)
        .update({
          isDeleted: false,
        });
    } catch (error) {
      console.error('Error un-deleting project:', error);
      return;
    }

    notification.success({
      message: 'Un-deleted',
      description: `Project "${project.label}" is un-deleted. Be careful next time leh.`,
    });
  }

  const columns = [
    {
      title: 'Projects',
      dataIndex: 'project',
      key: 'project',
      // sorter: (a: ProjectTableGroup, b: ProjectTableGroup) => a.project === b.project ? 0 : a.project < b.project ? 1 : -1,
      width: 300,
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      // sorter: (a: ProjectTableGroup, b: ProjectTableGroup) => a.project === b.project ? 0 : a.project < b.project ? 1 : -1,
    },
    {
      title: '',
      key: 'operations',
      align: 'right' as 'right',
      render: (value: any, project: ProjectTableGroup) => (
        <Space size="middle">
          <a href="#" onClick={(e) => { e.stopPropagation(); handleEditProject(project); }}>Edit</a>
          <Popconfirm
            title="Confirm delete this project hor?"
            onConfirm={() => handleDeleteProject({ key: project.key, label: project.project })}
            okText="Yes la"
            cancelText="No la"
          >
            <a href="#" onClick={(e) => { e.stopPropagation(); }}>Delete</a>
          </Popconfirm>
        </Space>
      )
    },
  ];

  const showUpdateMenuItemModal = () => {
    setShowEditMenuItemModal(true);
  }

  const closeUpdateMenuItemModal = () => {
    setShowEditMenuItemModal(false);
    setSelectedMenuItem(undefined);
  }

  const handleEditMenuItem = (menuItem: MenuItem) => {
    setSelectedMenuItem(menuItem);
    showUpdateMenuItemModal();
  }

  const handleDeleteMenuItem = async (menuItem: MenuItem) => {
    if (!menuItem.key) {
      console.error("Cannot delete a menu item that is not created yet.");
      return;
    }

    try {
      await firebase.firestore()
        .collection("menuItems")
        .doc(menuItem.key)
        .update({
          isEnabled: false,
          isDeleted: true,
        });
    } catch (error) {
      console.error('Error deleting menu item:', error);
      return;
    }

    notification.warning({
      message: 'Deleted',
      description: `Menu item "${menuItem.label}" is deleted. Click here to undo in the next 4 seconds.`,
      onClick: () => {
        handleUndoDeleteMenuItem(menuItem);
      },
    });
  }

  const handleUndoDeleteMenuItem = async (menuItem: MenuItem) => {
    if (!menuItem.key) {
      console.error("Cannot delete a menu item that is not created yet.");
      return;
    }

    try {
      await firebase.firestore()
        .collection("menuItems")
        .doc(menuItem.key)
        .update({
          isDeleted: false,
        });
    } catch (error) {
      console.error('Error un-deleting menu item:', error);
      return;
    }

    notification.success({
      message: 'Un-deleted',
      description: `Menu item "${menuItem.label}" is un-deleted. Be careful next time leh.`,
    });
  }

  const handleCancelUpdateMenuItem = () => {
    closeUpdateMenuItemModal();
  }

  const handleAddMenuItem = () => {
    setSelectedMenuItem({
      key: "",
      project: "",
      label: "",
      isEnabled: true,
      isDeleted: false,
      isComingSoon: true,
      url: "",
      hasConfig: false,
      configUrl: "",
      requiredRole: "",
    });
    showUpdateMenuItemModal();
  }

  const handleAddProject = () => {
    setSelectedProject({
      key: "",
      label: "",
    });
    setShowEditProjectModal(true);
  }

  const renderMenuItemsForProject = (project: ProjectTableGroup) => {
    const columns = [
      {
        title: 'Menu item',
        dataIndex: 'label',
        key: 'label',
        textWrap: 'word-break',
        width: 200,
        // sorter: (a: MenuItem, b: MenuItem) => a.label === b.label ? 0 : a.label < b.label ? 1 : -1,
      },
      // {
      //   title: 'Url',
      //   dataIndex: 'url',
      //   key: 'url',
      //   render: (url?: string) => (url && <span style={{ fontSize: '0.8em', fontFamily: 'monospace' }}>{url}</span>),
      // },
      {
        title: 'Enabled',
        dataIndex: 'isEnabled',
        key: 'isEnabled',
        width: 100,
        render: (isEnabled?: boolean) => isEnabled === undefined ? null : <Checkbox checked={isEnabled} />
      },
      {
        title: 'Coming soon',
        dataIndex: 'isComingSoon',
        key: 'isComingSoon',
        width: 100,
        render: (isComingSoon?: boolean) => isComingSoon === undefined ? null : <Checkbox checked={isComingSoon} />
      },
      {
        title: 'Required role',
        dataIndex: 'requiredRole',
        key: 'requiredRole',
        render: (role?: string) => (
          role &&
          <Tag color="green" key={role} style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {role.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: '',
        key: 'operations',
        align: 'right' as 'right',
        render: (value: any, menuItem: MenuItem) => (
          <Space size="middle">
            <a href="#" onClick={() => handleEditMenuItem(menuItem)}>Edit</a>
            {/*<Button onClick={() => handleEditMenuItem(menuItem)}>Edit</Button>*/}
            <Popconfirm
              title="Confirm delete this menu item hor?"
              onConfirm={() => handleDeleteMenuItem(menuItem)}
              okText="Yes la"
              cancelText="No la"
            >
              <a href="#">Delete</a>
            </Popconfirm>
          </Space>
        )
      },
    ];

    return <Table
      className="menu-item-table"
      columns={columns}
      dataSource={project.childrenMenuItems}
      pagination={false}
    />;
  }

  // console.log('Projects:', projects);
  return ReactDOM.createPortal(
    <div style={{ padding: '64px 0 64px', margin: '0 auto', width: 946 }}>
      <Space size="middle" style={{ marginBottom: 16 }}>
        <Button onClick={() => handleAddMenuItem()} type="primary">
          Add a menu item
        </Button>
        <Button onClick={() => handleAddProject()} type="primary">
          Add a project
        </Button>
      </Space>

      <Space size="middle" style={{ marginBottom: 16, float: 'right' }}>
        <Button onClick={() => setShowMenuConfiguration(false)}>
          Close menu management
        </Button>
      </Space>
      <Table
        className="project-table"
        columns={columns}
        dataSource={tableData}
        expandable={{ expandedRowRender: renderMenuItemsForProject, expandRowByClick: true }}
        pagination={false}
      />
      {selectedMenuItem && <MenuItemConfigurationFormModal
        user={user}
        projects={projects}
        menuItem={selectedMenuItem}
        visible={showEditMenuItemModal}
        onCancel={handleCancelUpdateMenuItem}
        onClose={closeUpdateMenuItemModal}
      />}
      {selectedProject && <ProjectConfigurationFormModal
        user={user}
        projects={projects}
        project={selectedProject}
        visible={showEditProjectModal}
        onCancel={handleCancelUpdateProject}
        onClose={closeUpdateProjectModal}
      />}
    </div>,
    document.getElementById("menu-configuration-ui")!
  );
}

export default MenuConfiguration;
