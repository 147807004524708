import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ReactDOM from "react-dom";
import {Button, Checkbox, Modal, notification, Popconfirm, Space, Spin, Table, Tag, Tooltip} from "antd";
import * as firebase from "firebase";
import {AppContext} from "../../App";
import {User, userDisplayNameSorter} from "../../common/types/user";
import {Project} from "../../common/types/project";
import './UserManagement.scss';
import UserFormModal from "./UserFormModal";
import {MenuItem} from "../../common/types/menuItem";

const UserManagement: FC = () => {

  const {
    user,
    showMenuConfiguration,
    setShowMenuConfiguration,
    showUserManagement,
    setShowUserManagement
  } = useContext(AppContext);

  const [users, setUsers] = useState<User[]>([]);
  const [existingUserRoles, setExistingUserRoles] = useState<string[]>([]);
  const [existingMenuItemRoles, setExistingMenuItemRoles] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);

  // Keep users updated by listening to users collection on firestore.
  useEffect(() => {
    let stopListening: Function;
    try {
      stopListening = firebase.firestore()
        .collection("users")
        .where("isDeleted", "==", false)
        .onSnapshot(function(querySnapshot) {
          const users: User[] = [];
          let existingRoles: string[] = [];
          querySnapshot.forEach(function(doc) {
            const user = doc.data() as User;
            user.key = doc.id;
            users.push(user);
            existingRoles = existingRoles.concat(user.roles);
          });
          users.sort(userDisplayNameSorter);
          setUsers(users);
          setExistingUserRoles(existingRoles.sort());
        });
    } catch (error) {
      console.error('Error loading projects:', error);
      return;
    }

    return () => {
      if (stopListening) {
        stopListening();
      }
    }
  }, []);

  // Keep menuItems updated by listening to menuItems collection on firestore.
  useEffect(() => {
    let stopListening: Function;
    try {
      stopListening = firebase.firestore()
        .collection("menuItems")
        .where("isDeleted", "==", false)
        .onSnapshot(function(querySnapshot) {
          let existingRoles: string[] = [];
          querySnapshot.forEach(function(doc) {
            const menuItem = doc.data() as MenuItem;
            existingRoles.push(menuItem.requiredRole);
          });
          setExistingMenuItemRoles(existingRoles);
        });
    } catch (error) {
      console.error('Error loading menu items:', error);
      return;
    }

    return () => {
      if (stopListening) {
        stopListening();
      }
    }
  }, []);

  // De-duplicate and sort roles from both users and menu items.
  const existingRoles = useMemo(() => {
    const hash: { [key: string]: string} = {};
    existingMenuItemRoles.forEach(role => hash[role] = role);
    existingUserRoles.forEach(role => hash[role] = role);
    const roles: string[] = [];
    for (const role in hash) {
      roles.push(role);
    }
    return roles.sort();
  }, [existingMenuItemRoles, existingUserRoles])

  const handleAddUser = () => {
    setShowAddUserModal(true);
  }

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setShowEditUserModal(true);
  }

  const handleCancelEditUser = () => {
    setSelectedUser(undefined);
    setShowEditUserModal(false);
  }

  const handleDeleteUser = async (user: User) => {
    setIsDeletingUser(true);
    let result: any;
    try {
      const deleteUser = firebase.functions().httpsCallable('deleteUser');
      const response = await deleteUser({ uid: user.key });
      result = response?.data;
      console.log('result:', result);
    } catch (error) {
      console.error("Error calling deleteUser, error:", error);
      notification.error({
        message: "Error deleting user",
        description: "Check console for more details.",
      });
      setIsDeletingUser(false);
      return;
    }

    if (!result) {
      notification.error({
        message: "Error deleting user",
        description: "Did not receive a valid response from server!",
      });
      setIsDeletingUser(false);
      return;
    }

    const { isError, message } = result;
    if (isError) {
      notification.error({
        message: "Error deleting user",
        description: message,
      });
      setIsDeletingUser(false);
      return;
    }

    notification.success({
      message: "Deleted user",
      description: message,
    });
    setIsDeletingUser(false);
  }

  const columns = [
    {
      title: 'User',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: userDisplayNameSorter,
      width: 120,
      render: (displayName: string, user: User) => (
        <Tooltip title={user.key}>
          <span>{displayName}</span>
        </Tooltip>
      )
    },
    // {
    //   title: 'Key',
    //   dataIndex: 'key',
    //   key: 'key',
    // },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Admin',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      render: (value?: boolean) => value === undefined ? null : <Checkbox checked={value} />
    },
    {
      title: 'Editor',
      dataIndex: 'isEditor',
      key: 'isEditor',
      render: (value?: boolean) => value === undefined ? null : <Checkbox checked={value} />
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles: string[] = []) => (
        roles.map(role => (
          <Tag color="green" key={role} style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {role.toUpperCase()}
          </Tag>
        ))
      ),
    },
    {
      title: '',
      key: 'operations',
      align: 'right' as 'right',
      render: (value: any, user: User) => (
        <Space size="middle">
          <a href="#" onClick={() => handleEditUser(user)}>Edit</a>
          {user.isAdmin ? (
            <Tooltip title="You cannot delete an admin user. Remove admin from user first.">
              <Button type="link" disabled={true}>Delete</Button>
            </Tooltip>
          ) : (
            <Popconfirm
              title="Confirm delete this user hor?"
              onConfirm={() => handleDeleteUser(user)}
              okText="Yes la"
              cancelText="No la"
            >
              <a href="#" onClick={(e) => { e.stopPropagation(); }}>Delete</a>
            </Popconfirm>
          )}
        </Space>
      )
    },
  ];

  return ReactDOM.createPortal(
      <div style={{ padding: '64px 0 64px', margin: '0 auto', width: 946 }}>
        <Space size="middle" style={{ marginBottom: 16 }}>
          <Button onClick={() => handleAddUser()} type="primary">
            Add a user
          </Button>
        </Space>

        <Space size="middle" style={{ marginBottom: 16, float: 'right' }}>
          <Button onClick={() => setShowUserManagement(false)}>
            Close user management
          </Button>
        </Space>
        <Table
          className="project-table"
          columns={columns}
          dataSource={users}
          pagination={false}
        />
        {selectedUser && <UserFormModal
          user={selectedUser}
          existingRoles={existingRoles}
          visible={showEditUserModal}
          onCancel={handleCancelEditUser}
          onClose={handleCancelEditUser}
        />}
        <Modal
          title="You do not add users"
          visible={showAddUserModal}
          onOk={() => setShowAddUserModal(false)}
          onCancel={() => setShowAddUserModal(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => setShowAddUserModal(false)}>
              Oh right, I knew that
            </Button>,
          ]}
        >
          <p>The only way to add users is to have them register using the log in form when they first access the site.</p>
        </Modal>
        <Modal
          title="Deleting user"
          visible={isDeletingUser}
          onOk={() => {}}
          onCancel={() => {}}
          maskClosable={false}
          footer={null}
        >
          <div style={{ padding: 32, textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        </Modal>
      </div>,
      document.getElementById("user-management-ui")!
  );
}

export default UserManagement;
