import React, {FC, useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom'
import './MainNavigation.scss';
import {MenuItem} from "../../types/menuItem";
import {User} from "../../types/user";
import * as firebase from "firebase";
import {Project} from "../../types/project";
import {AppContext} from "../../../App";

declare global {
    interface Window {
        loadURL: Function;
    }
}

interface DemoLinkProps {
    config: MenuItem;
    user: User;
}

const DemoLink: FC<DemoLinkProps> = ({
                                         config: { label, url = "", hasConfig = false, configUrl = "", isComingSoon = false, requiredRole },
                                         user,
}) => {

    const loadUrl = (id: string, path: string, id2: string, id3: string) => {
        return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            if (window.loadURL) {
                window.loadURL(id, path, id2, id3);
            }
        }
    }

    // if ((user.roles.find(item => item === requiredRole) || []).length === 0) {
    //     return null;
    // }

    if (isComingSoon) {
        return (
          <li>
              <a href="/#">{label} (Coming soon)</a>
              <ul></ul>
          </li>
        );
    }
    return (
      <li>
          <a href="/" onClick={loadUrl("CFO_00107", url, "P003", "CFO_D0012")}>
              {label}
          </a>
          {hasConfig && (
            <a href="/" onClick={loadUrl("CFO_00107", configUrl, "P003", "CFO_D0012")}>
                <div className="config-link" />
            </a>
          )}
          <ul></ul>
      </li>
    );
}

interface MainNavigationProps {
    user: User;
}

const MainNavigation: FC<MainNavigationProps> = ({ user }) => {

    const {
        showMenuConfiguration,
        setShowMenuConfiguration,
        showUserManagement,
        setShowUserManagement
    } = useContext(AppContext);

    const [menuItems, setMenuItems] = useState<MenuItem[]>();
    const [projectsInfo, setProjectsInfo] = useState<Project[]>([]);

    // Keep menuItems updated by listening to menuItems collection on firestore.
    useEffect(() => {
        let stopListening: Function;
        try {
            stopListening = firebase.firestore()
              .collection("menuItems")
              .where("isDeleted", "==", false)
              .onSnapshot(function(querySnapshot) {
                  const menuItems: MenuItem[] = [];
                  querySnapshot.forEach(function(doc) {
                      const menuItem = doc.data() as MenuItem;
                      if (menuItem.isEnabled) {
                        menuItems.push(menuItem);
                      }
                  });
                  setMenuItems(menuItems);
              });
        } catch (error) {
            console.error('Error loading menu items:', error);
            return;
        }

        return () => {
            if (stopListening) {
                stopListening();
            }
        }
    }, []);

    // Keep projects updated by listening to projects collection on firestore.
    useEffect(() => {
        let stopListening: Function;
        try {
            stopListening = firebase.firestore()
              .collection("projects")
              .onSnapshot(function(querySnapshot) {
                  const projects: Project[] = [];
                  querySnapshot.forEach(function(doc) {
                      const project = doc.data() as Project;
                      project.key = doc.id;
                      projects.push(project);
                  });
                  setProjectsInfo(projects);
              });
        } catch (error) {
            console.error('Error loading projects:', error);
            return;
        }

        return () => {
            if (stopListening) {
                stopListening();
            }
        }
    }, []);

    const loadUrl = (id: string, path: string, id2: string, id3: string) => {
        return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            // if (window.loadURL) {
            //     window.loadURL(id, path, id2, id3);
            // }
        }
    }

    let visibleMenuItems = 0;
    const projectKeys: string[] = [];
    const projects: { [key: string]: { key: string; label: string; items: MenuItem[], requiredRole: string } } = {};

    (menuItems || []).forEach(item => {

        // Skip items which user do not have access to.
        if (!user.isAdmin && (user.roles.find(role => role === item.requiredRole) || []).length === 0) {
            return null;
        }

        const projectKey = item.project;
        const projectInfo = projectsInfo.find(project => project.key === projectKey);

        if (typeof projects[projectKey] === "undefined") {
            projects[projectKey] = {
                key: projectKey,
                label: projectInfo ? projectInfo.label : projectKey,
                items: [],
                requiredRole: item.requiredRole,
            };
            projectKeys.push(projectKey);
        }
        projects[projectKey].items.push(item);
        visibleMenuItems++;
    });

    return ReactDOM.createPortal(
        <>
            <div className='l_nav_pri_content'>
                <ul className='nav_pri clearfix menu'>
                    <li className='first'>
                        <a href="/" onClick={loadUrl("CFO_00001", "dtb/#", "P001", "CFO_ROOT")}
                        title='Home' className='home_link'>
                        <div className='nav_pri_l'>
                            <div className='nav_pri_r'>
                                <div className='nav_pri_m'><span className='home_icn_lnk'>Home</span></div>
                            </div>
                        </div>
                    </a></li>
                    <li>
                        <a href="/" onClick={() => {}} title='Accounts' className='drop'>
                            <div className='nav_pri_l'>
                                <div className='nav_pri_r'>
                                    <div className='nav_pri_m'>Accounts</div>
                                </div>
                            </div>
                        </a>
                        <div className='nav_dd_content_trans nav_width_standard pri_hover'>
                            <div className='dd_txt_standard'>
                                <ul className='dd_links'>
                                    <li className="breakWordAll"><span
                                        className='menu_cat_title'> </span></li>
                                    <li><a href="/" onClick={loadUrl("CFO_00003", "dtb/pf_summary.action?do=PortfolioSummaryRequest", "P003", "CFO_D0001")}>
                                        Overview</a>
                                        <ul></ul>
                                    </li>
                                    <li><a href="/" onClick={loadUrl("CFO_00103", "dtb/pf_current_account.action?do=Search", "P003", "CFO_D0001")}>
                                        Statement</a>
                                        <ul></ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li><a href="/" onClick={() => {}} title='Transactions' className='drop'>
                        <div className='nav_pri_l'>
                            <div className='nav_pri_r'>
                                <div className='nav_pri_m'>Transactions</div>
                            </div>
                        </div>
                    </a>
                        <div className='nav_dd_content_trans nav_width_double_b pri_hover '>
                            <div className='nav_width_standard_dd_blue dd_double_l'>
                                <ul className='dd_links'>
                                    <li className="breakWordAll"><span className='menu_cat_title'>Make a transfer to ...</span></li>
                                    <li><a href="/" onClick={loadUrl("CFO_00012", "dtb/ift.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_00011")}>
                                        An OCBC account</a>
                                        <ul></ul>
                                    </li>
                                    <li><a href="/" onClick={loadUrl("CFO_00013", "dtb/giro_payment.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_00011")}>
                                        A bank locally</a>
                                        <ul></ul>
                                    </li>
                                    <li><a href="/" onClick={loadUrl("CFO_00014", "dtb/tt_payment.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_00011")}>
                                        A bank overseas</a>
                                        <ul></ul>
                                    </li>
                                    <hr className='mtl mrl mlm dd_bar_left'/>
                                    <li className="breakWordAll"><span
                                        className='menu_cat_title'> </span></li>
                                    <li><a href="/" onClick={loadUrl("CFO_00015", "dtb/bill_payment.action?do=TransactionRequest", "P003", "CFO_D0002")}>
                                        Bill payment</a>
                                        <ul></ul>
                                    </li>
                                    <hr className='mtl mrl mlm dd_bar_left'/>
                                    <li className="breakWordAll"><span
                                        className='menu_cat_title'> </span></li>
                                    <li><a href="/" onClick={loadUrl("CFO_00016", "dtb/payroll.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_D0003")}>
                                        Payroll</a>
                                        <ul></ul>
                                    </li>
                                    <li><a href="/" onClick={loadUrl("CFO_00017", "dtb/mgmt_payroll.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_D0003")}>
                                        Management payroll</a>
                                        <ul></ul>
                                    </li>
                                </ul>
                            </div>
                            <div className='nav_width_standard_dd_blue dd_double_l tf_right'>
                                <div className='dd_txt_align_l dd_double_lb_bluex'>
                                    <ul className='dd_links'>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'> </span></li>
                                        <li><a href="/" onClick={loadUrl("CFO_00022", "/dtb/fileUpload.action?do=FileUploadRequest", "P003", "CFO_D0005")}>
                                            Upload file</a>
                                            <ul></ul>
                                        </li>
                                        <hr className='mtl mrl dd_bar_left'/>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'> </span></li>
                                        <li><a href="/" onClick={loadUrl("CFO_00023", "dtb/giro_collection.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_D0007")}>
                                            Funds collection</a>
                                            <ul></ul>
                                        </li>
                                        <hr className='mtl mrl dd_bar_left'/>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'> </span></li>
                                        <li><a href="/" onClick={loadUrl("CFO_00019", "dtb/cashier_order.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_D0006")}>
                                            Cashier's order</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00020", "dtb/demand_draft.action?do=PaymentTransactionTemplateRequest", "P003", "CFO_D0006")}>
                                            Demand draft</a>
                                            <ul></ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='dd_footer double_txt'>
                                <div className='dd_footer_wrapper'>
                                    <span className='dd_footer_trans'>
                                        <a href="/" onClick={loadUrl("CFO_00025", "dtb/trxStatusInqCash.action?do=SearchRequest", "P003", "CFO_00010")}>
                                            Transaction status
                                        </a>
                                    </span><br />
                                    <span className='dd_footer_trans'>
                                        <a href="/" onClick={loadUrl("CFO_00026", "/dtb/signatory/task.action?do=Search&signContext.taskListProductCategoryCode=C477A2DEA36950D8E044000C290B6880&signContext.taskListPageIndex=1", "P003", "CFO_00010")}>
                                            Pending transactions
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="/" onClick={() => {}} title='Cheques' className='drop'>
                            <div className='nav_pri_l'>
                                <div className='nav_pri_r'>
                                    <div className='nav_pri_m'>Cheques</div>
                                </div>
                            </div>
                        </a>
                        <div className='nav_dd_content_trans nav_width_standard pri_hover'>
                            <div className='dd_txt_standard'>
                                <ul className='dd_links'>
                                    <li className="breakWordAll"><span
                                        className='menu_cat_title'> </span></li>
                                    <li><a href="/" onClick={loadUrl("CFO_00029", "dtb/stop_cheque.action?do=AddNew", "P003", "CFO_D0008")}>
                                        Stop cheque payment</a>
                                        <ul></ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li><a href="/" onClick={() => {}} title='Trade Finance'
                        className='drop'>
                        <div className='nav_pri_l'>
                            <div className='nav_pri_r'>
                                <div className='nav_pri_m'>Trade Finance</div>
                            </div>
                        </div>
                    </a>
                        <div className='nav_dd_content_trans nav_width_double_b pri_hover' style={{ right: 1 }}>
                            <div className='nav_width_standard_dd_blue dd_double_l'>
                                <ul className='dd_links'>
                                    <li className="breakWordAll"><span
                                        className='menu_cat_title'>Import</span></li>
                                    <li><a href="/#"> Letter of Credit</a>
                                        <ul>
                                            <li className="breakWordAll">
                                                <a href="/" onClick={loadUrl("CFO_00034", "dtb/import_letter_credit.action?do=Entry", "P003", "CFO_00033")} className="pll fontWeightNormalImportant">New Application</a>
                                            </li>
                                            <li className="breakWordAll">
                                                <a href="/" onClick={loadUrl("CFO_00035", "dtb/import_letter_credit_am.action?do=Entry", "P003", "CFO_00033")} className="pll fontWeightNormalImportant">Amendment</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="/" onClick={loadUrl("CFO_00039", "dtb/import_bill.action?do=Entry", "P003", "CFO_00032")}>
                                        Import Bill under Letter of Credit</a>
                                        <ul></ul>
                                    </li>
                                    <li><a href="/" onClick={loadUrl("CFO_00041", "dtb/import_collection.action?do=Entry", "P003", "CFO_00032")}>
                                        Import Documentary Collection</a>
                                        <ul></ul>
                                    </li>
                                </ul>
                            </div>
                            <div className='nav_width_standard_dd_blue dd_double_l tf_right'>
                                <div className='dd_txt_align_l dd_double_lb_bluex'>
                                    <ul className='dd_links'>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'>Guarantee</span></li>
                                        <li>
                                            <a href="/#"> Bank Guarantee / Standby Letter of Credit</a>
                                            <ul>
                                                <li className="breakWordAll">
                                                    <a href="/" onClick={loadUrl("CFO_00060", "dtb/import_bankguarantee.action?do=Entry", "P003", "CFO_00059")} className="pll fontWeightNormalImportant">New Application</a>
                                                </li>
                                                <li className="breakWordAll">
                                                    <a href="/" onClick={loadUrl("CFO_00061", "dtb/import_bankguarantee_am.action?do=Entry", "P003", "CFO_00059")} className="pll fontWeightNormalImportant">Amendment</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <hr className='mtl mrl dd_bar_left'/>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'>Trade Financing</span></li>
                                        <li><a href="/" onClick={loadUrl("CFO_00066", "dtb/invoice_financing.action?do=Entry", "P003", "CFO_00065")}>
                                            Invoice Financing</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00073", "dtb/import_trade_financing_am_se.action?do=Entry", "P003", "CFO_00065")}>
                                            Trade Financing Amendment and Settlement</a>
                                            <ul></ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='dd_footer double_txt'>
                                <div className='dd_footer_wrapper'>
                                    <span className='dd_footer_trans'>
                                        <a href="/" onClick={loadUrl("CFO_00074", "dtb/trxStatusInqTrade.action?do=SearchRequest", "P003", "CFO_00031")}>
                                            Trade Finance transaction status
                                        </a>
                                    </span><br />
                                    <span className='dd_footer_trans'>
                                        <a href="/" onClick={loadUrl("CFO_00075", "/dtb/signatory/task.action?do=Search&signContext.taskListProductCategoryCode=C477A2DEA36A50D8E044000C290B6880&signContext.taskListPageIndex=1", "P003", "CFO_00031")}>
                                            Pending Trade Finance transactions
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li><a href="/" onClick={() => {}} title='Tools' className='drop'>
                        <div className='nav_pri_l'>
                            <div className='nav_pri_r'>
                                <div className='nav_pri_m'>Tools</div>
                            </div>
                        </div>
                    </a>
                        <div className='nav_dd_content_trans nav_width_double_b pri_hover toolsNav' style={{ right: 1 }}>
                            <div className='nav_width_standard_dd_blue dd_double_l'>
                                <ul className='dd_links'>
                                    <li className="breakWordAll"><span
                                        className='menu_cat_title'> </span></li>
                                    <li><a href="/" onClick={loadUrl("CFO_00077", "/dtb/report_cfo.action?do=ReportTemplateListingRequest", "P003", "CFO_D0009")}>
                                        Create reports</a>
                                        <ul></ul>
                                    </li>
                                    <li><a href="/" onClick={loadUrl("CFO_00078", "/dtb-fr/front_end.action?do=SearchCFORequest", "P003", "CFO_D0009")}>
                                        File Repository (Download Files)</a>
                                        <ul></ul>
                                    </li>
                                    <hr className='mtl mrl mlm dd_bar_left'/>
                                    <li className="breakWordAll"><span
                                        className='menu_cat_title'> </span></li>
                                    <li><a href="/" onClick={loadUrl("CFO_00079", "/dtb/remittance_advice.action?do=RemittanceAdviceSearch", "P003", "CFO_D0010")}>
                                        Remittance advice tracking</a>
                                        <ul></ul>
                                    </li>
                                </ul>
                            </div>
                            <div className='nav_width_standard_dd_blue dd_double_l tf_right'>
                                <div className='dd_txt_align_l dd_double_lb_bluex'>
                                    <ul className='dd_links'>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'> </span></li>
                                        <li><a href="/" onClick={loadUrl("CFO_00102", "tran.view?do=SelfChangePasswordRequest", "P003", "CFO_D0012")}>
                                            Change password</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00107", "/dtb/mobile_harvesting.action?do=PageFormRequest", "P003", "CFO_D0012")}>
                                            Change contact details</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00107", "https://clients.redairship.com/ocbc-velocity-account-maintenance/preview/react/change-address", "P003", "CFO_D0012")}>
                                            Change address</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00107", "https://clients.redairship.com/ocbc-velocity-account-maintenance/preview/react/update-business-name", "P003", "CFO_D0012")}>
                                            Change business name</a>
                                            <ul></ul>
                                        </li>
                                        <hr className='mtl mrl dd_bar_left'/>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'> </span></li>
                                        <li><a href="/" onClick={loadUrl("CFO_00081", "dtb/tmpl_mt.action?do=SearchRequest", "P003", "CFO_D0011")}>
                                            Transaction templates</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00082", "/dtb/fav_bene.action?do=AddRequest", "P003", "CFO_D0011")}>
                                            Manage Payers/Payees/Billers</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00104", "dtb/pay_now_sgqr.action?do=SearchRequest", "P003", "CFO_D0011")}>
                                            Manage PayNow & SGQR</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00111", "/dtb/manage_digital_token.action?do=GetUserToken", "P003", "CFO_D0011")}>
                                            Manage OneToken</a>
                                            <ul></ul>
                                        </li>
                                        <hr className='mtl mrl dd_bar_left'/>
                                        <li className="breakWordAll"><span
                                            className='menu_cat_title'> </span></li>
                                        <li><a href="/" onClick={loadUrl("CFO_00118", "dtb/bfs_self_declaration.action?do=DisplaySelfDeclaration", "P003", "CFO_D0013")}>
                                            Start Digital</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00117", "BVOAUTH2/oauth/oAuthentication.action?do=AfterLoginBV", "P003", "CFO_D0013")}>
                                            Login to Digital Business Dashboard</a>
                                            <ul></ul>
                                        </li>
                                        <li><a href="/" onClick={loadUrl("CFO_00109", "dtb/conn_acct_Inq.action?do=Search", "P003", "CFO_D0013")}>
                                            Apps linked to Velocity</a>
                                            <ul></ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className='last'>
                        <a href="/" onClick={() => {}} title='Red Airship Demos'
                            className='drop'>
                            <div className='nav_pri_l'>
                                <div className='nav_pri_r'>
                                    <div className='nav_pri_m'>RAS Demos</div>
                                </div>
                            </div>
                        </a>
                        <div className='nav_dd_content_trans nav_width_double_b pri_hover toolsNav' style={{ right: 1 }}>
                            {projectKeys.map(projectKey => {
                                const { label, items } = projects[projectKey];
                                return (
                                  <div key={projectKey} className='nav_width_standard_dd_blue dd_double_l'>
                                      <ul className='dd_links'>
                                          <li className="breakWordAll">
                                              <span className='menu_cat_title'>{label}</span>
                                          </li>
                                          {items.map((menuItem, index) => <DemoLink key={index} config={menuItem} user={user!} />)}
                                      </ul>
                                  </div>
                                );
                            })}
                            {visibleMenuItems === 0 && (
                              <div className='nav_width_standard_dd_blue dd_double_l'>
                                  <ul className='dd_links'>
                                      <li className="breakWordAll">
                                          <span className='menu_cat_title'>Oh no</span>
                                      </li>
                                      <li><a href="/#">You do not have access to any demo links so far! Check with RAS if they have given you the right permissions.</a></li>
                                  </ul>
                              </div>
                            )}
                            {user.isAdmin && (
                              <div className='nav_width_standard_dd_blue dd_double_l'>
                                  <ul className='dd_links'>
                                      <li className="breakWordAll">
                                          <span className='menu_cat_title'>Admin</span>
                                      </li>
                                      <li><a href="#" onClick={() => setShowMenuConfiguration(true)}>Configure menu</a></li>
                                      <li><a href="#" onClick={() => setShowUserManagement(true)}>Configure users</a></li>
                                  </ul>
                              </div>
                            )}
                        </div>
                    </li>
                </ul>
                <div id="ajaxResult"><input type="hidden" name="mntFlag"/></div>
            </div>

            <div id="header-demo-note">
                <p>
                    Almost none of these work as this is a demo, not the real thing!
                </p>
                <div className="arrow"></div>
            </div>
        </>,
        document.getElementById('l_nav_pri')!
    )
}

const MainNavigationWrapper: FC = () => {
    const {
        user
    } = useContext(AppContext);

    if (!user) {
        return <div>User is empty!</div>;
    }

    return <MainNavigation user={user} />
}

export default MainNavigationWrapper;
