
export interface User {
  key?: string;
  isDeleted: boolean;
  displayName?: string;
  email?: string;
  isAdmin: boolean;
  isEditor: boolean;
  isViewer: boolean;
  roles: string[];
}

export const userDisplayNameSorter = (a: User, b: User) => {
  const nameA = (a.displayName || "").toLowerCase();
  const nameB = (b.displayName || "").toLowerCase();
  if (nameA === nameB) {
    return 0;
  }
  return (nameA < nameB) ? -1 : 1;
}

export const userHasRole = (user: User, role: string): boolean => {
  const foundRole = user.roles.find(role => role === "viewer");
  return !!foundRole;
}
